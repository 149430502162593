<template>
  <div class="flex items-center mb-4 space-x-4">
    <span class="bg-white rounded-xl p-2 font-bold text-black">{{ text }}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DropUpItem",
  props: {
    text: {
      type: String
    }
  }
};
</script>

<style scoped></style>
