<template>
  <div class="fixed flex flex-col items-end bottom-24 right-4">
    <transition name="slide-up">
      <div v-if="dropUpOpen" class="mr-2 flex flex-col items-end">
        <drop-up-item
          @click.native="$emit('onBuddyCallClick')"
          v-if="getBuddies.length"
          :text="$t('pages.emergency.call_safety_buddy')"
        >
          <div
            class=" h-12 w-12 flex items-center justify-center rounded-full bg-brand-primary"
          >
            <icon-phone class="text-black" />
          </div>
        </drop-up-item>
        <drop-up-item :text="$t('pages.emergency.call_emergency_services')">
          <div
            class="h-12 w-12 flex items-center justify-center rounded-full bg-red-500"
          >
            <a href="tel:112">
              <icon-phone class="text-black" />
            </a>
          </div>
        </drop-up-item>
      </div>
    </transition>
    <div
      @click="dropUpOpen = !dropUpOpen"
      class=" h-16 w-16 bg-red-500 flex items-center justify-center rounded-full"
    >
      <icon-phone class="text-black" />
    </div>
  </div>
</template>

<script>
import IconPhone from "assets/icons/phone.svg";
import DropUpItem from "./DropUpItem";
import { mapGetters } from "vuex";

export default {
  name: "DropUp",

  components: {
    DropUpItem,
    IconPhone,
  },

  data() {
    return {
      dropUpOpen: false,
    };
  },

  computed: {
    ...mapGetters(["getBuddies"]),
  },
};
</script>