<template>
  <div class="relative">
    <h1 class="mb-4 text-3xl font-bold">
      {{ $t("pages.emergency.title") }}
    </h1>

    <div class="space-y-3">
      <router-link
        :to="{ name: 'info-emergency-article', params: { id: article.id } }"
        class="focus:outline-none m-0 block w-full appearance-none p-0"
        v-for="article in articles"
        :key="article.id"
        @click="showPopup(article.id)"
      >
        <list-item>
          <template v-slot:start>
            <icon-question-circle class="h-6 w-6" />
          </template>
          <template v-slot:default>
            <span class="max-w-full select-none font-medium">{{
              $t(article.title)
            }}</span>
          </template>
        </list-item>
      </router-link>
    </div>

    <drop-up v-if="!!articles" @onBuddyCallClick="test" />

    <modal :show="buddyModalOpen" @close="buddyModalOpen = false">
      <div class="p-6">
        <h6 class="mb-4 text-base font-semibold leading-6">
          {{ $t("events.btn--call-friend") }}:
        </h6>

        <div class="space-y-3 p-3">
          <div
            v-for="buddy in getBuddies"
            :key="buddy.name"
            class="relative block w-full"
          >
            <list-item class="mt-3">
              <template v-slot:default>
                <a
                  :href="`tel:${buddy.phone}`"
                  target="_self"
                  class="block w-full"
                >
                  <div class="absolute inset-0"></div>
                  <div class="flex select-none flex-col">
                    <div class="text-sm font-medium">{{ buddy.name }}</div>
                    <div class="text-xs italic">{{ buddy.phone }}</div>
                  </div>
                </a>
              </template>
              <template v-slot:end>
                <icon-call class="h-6 w-6 text-green-500" />
              </template>
            </list-item>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Repository from "repositories/RepositoryFactory";
import ListItem from "components/ListItem.vue";
import IconQuestionCircle from "assets/icons/question-circle.svg";
import DropUp from "components/Info/Emergency/DropUp";
import IconCall from "assets/icons/call.svg";
import Modal from "components/Modal";
const EmergencyRepository = Repository.get("emergency");

export default {
  name: "info-emergency-index",

  components: { DropUp, ListItem, IconQuestionCircle, Modal, IconCall },

  data() {
    return {
      buddyModalOpen: false,
    };
  },

  methods: {
    test() {
      this.buddyModalOpen = true;
    },
  },

  computed: {
    ...mapGetters(["isCheckedIn", "getBuddies"]),
    articles() {
      return EmergencyRepository.entries();
    },
  },
};
</script>
